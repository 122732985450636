@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

$screen-xs: 767px;

$inter: "Inter", sans-serif;
$montserrat: 'Montserrat';

$text-primary01: #383838;
$text-primary02: #5A7184;
$text-primary03: #979797;

$border-primary01: #F3F3F3;

$white: #ffffff;
$light-white: rgba(255, 255, 255, 0.9);
$black: #000000;

$darkblue: #282F97;
$darkblue2: #21367F;
$box-shadow01: 0 1px 119px rgba(0, 0, 0, 0.15);
$btn-shadow: 0 2.01389px 2.01389px rgba(0, 0, 0, 0.25);
$transition: all 0.2s ease-in-out;

body {
    overflow-x: hidden !important;
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
    background-color: $white;
}

//      ****************************  Container  ****************************

.container {
    @media (min-width: 1400px) {
        max-width: none !important;
        width: 80% !important;
    }
    // @media screen and (min-width: 992px) and (max-width:1200px) {
    //     max-width: 960px !important;
    // }

    &.pricing_container {
        @media (min-width: 1400px) {
            width: 55% !important;
        }
    }
}

//      ****************************  Text Element  ****************************


h1,
.h1 {
    font-family: $inter;
    font-size: 52px !important;
    font-weight: 700 !important;
    line-height: 66px !important;
    letter-spacing: -1.5px;
    color: $darkblue !important;
    margin-bottom: 0px !important;

    @media screen and (min-width: 2000px) {
        font-size: 3.2vw !important;
        line-height: 4.2vw !important;
    }

    @media screen and (max-width: 991px) {
        font-size: 40px !important;
        line-height: 55px !important;
    }

    @media screen and (max-width: 568px) {
        font-size: 35px !important;
        line-height: 45px !important;
    }
}

h2,
.h2 {
    font-family: $inter;
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 58px;
    color: $darkblue !important;
    margin-bottom: 0px !important;

    @media screen and (max-width: 991px) {
        font-size: 30px !important;
        line-height: 40px;
    }

    @media screen and (max-width: 568px) {
        font-size: 28px !important;
        line-height: 38px !important;
    }

    @media screen and (min-width: 2000px) {
        font-size: 2.5vw !important;
        line-height: 4vw !important;
    }
}

h5 , .h5{
    font-family: $inter;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 28px !important;
    margin-bottom: 0px !important;
    @media screen and (min-width: 2000px) {
        font-size: 1.4vw !important;
        line-height: 2vw !important;
    }
}

h6,
.h6 {
    font-family: $inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 0px !important;

    &.welcomeh6 {
        color: #5A7184 !important;
        font-weight: 400 !important;
        line-height: 30px !important;

        strong {
            color: #5A7184 !important;
            font-weight: 600 !important;
        }

        @media screen and (min-width: 2000px) {
            font-size: 1.2vw !important;
            line-height: 1.8vw !important;
        }

        @media screen and (max-width: 568px) {
            font-size: 18px !important;
        }
    }

    @media screen and (max-width: 568px) {
        font-size: 18px;
        line-height: 22px;
    }

    @media screen and (min-width: 2000px) {
        font-size: 1.8vw !important;
        line-height: normal !important;
    }
}

a {
    font-family: $inter;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 16.94px;
    text-align: center;
    color: $text-primary01 !important;

    @media screen and (min-width: 2000px) {
        font-size: 0.9vw !important;
        line-height: 2.2vw !important;
    }
    .badge{
        font-size: 10px;
        background: $darkblue !important;
        font-weight: 500;
        color: $white;
        border-radius: 20px;
        @media screen and (min-width: 2000px) {
            font-size: 0.6vw !important;
            border-radius: 0.5vw;
        }
    }
}

p {
    font-family: $inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: $text-primary02;
    margin-bottom: 0px !important;

    @media screen and (min-width: 2000px) {
        font-size: 1.1vw !important;
        line-height: 2vw !important;
    }

    @media screen and (max-width: 576px) {
        font-size: 14px;
        line-height: 20px;
    }
}

span {
    font-family: $inter;
}

strong {
    color: #000 !important;
    font-family: $inter;
}

ul {
    padding-left: 0 !important;
    margin-bottom: 0 !important;

    li {
        list-style: none;
        display: inline-block;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $text-primary01;
        font-family: $montserrat !important;
    }
}

.box-icon {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    img,
    svg {
        width: 20px;
        height: 20px;
        object-fit: contain;
    }
}

//      ****************************  Buttons Element  ****************************

.white-bg-btn {

    a,
    button {
        display: flex !important;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-family: $inter;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        padding: 8px 10px;
        position: relative;
        background-color: #fff;
        border: none !important;
        text-decoration: none !important;

        img {
            width: 20px !important;
            height: 20px !important;
        }

        @media screen and (min-width: 2000px) {
            font-size: 1vw !important;
            line-height: normal !important;
            padding: 0.5vw 3vw !important;
            gap: 0.5vw;
            height: 4vw;

            img {
                width: 1.5vw !important;
                height: 1.5vw !important;
            }
        }
    }

    a::before,
    button::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        padding: 1px;
        background: linear-gradient(90deg, $darkblue2 -3.37%, #3C61E5 100%);
        -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        mask-composite: exclude;

        @media screen and (min-width: 2000px) {
            border-radius: 0.4vw;
            padding: 0.05vw;
        }
    }
}

.gradiant-bg-btn {

    a,
    button {
        display: flex !important;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-family: $inter;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        padding: 8px 10px;
        background: linear-gradient(108.72deg, $darkblue2 31.47%, #1F7DDC 97.11%);
        border: 2px solid transparent;
        transition: border-color 0.3s, background-color 0.3s;
        padding: 10px;
        color: $white;
        border-radius: 5px;
        border: none !important;

        img {
            width: 28px !important;
            height: 28px !important;
        }

        @media screen and (min-width: 2000px) {
            font-size: 1vw !important;
            line-height: normal !important;
            padding: 1vw 3vw !important;
            gap: 0.5vw;
            width: auto !important;
            height: auto !important;
            border-radius: 0.4vw;

            img {
                width: 1.7vw !important;
                height: 1.7vw !important;
            }
        }

        @media screen and (max-width: 576px) {
            font-size: 14px;

            img {
                width: 24px !important;
                height: 24px !important;
            }
        }
    }
}



//      ****************************  Global Element  ****************************


.form {
    .form-group {
        position: relative;

        @media screen and (min-width: 2000px) {
            &:nth-child(2) {
                margin: 1vw 0px !important;
            }
        }

        input {
            border: 1px solid #00000026;
            outline: none !important;
            height: 50px !important;
            width: 100%;
            border-radius: 6px;
            font-family: $inter;
            font-size: 16px !important;
            font-weight: 400 !important;
            line-height: 19.36px;
            text-align: left;
            color: $text-primary03 !important;
            padding: 0px 10px 0px 50px;

            @media screen and (min-width: 2000px) {
                height: 4vw !important;
                font-size: 1.1vw !important;
                padding-left: 4vw !important;
                border-radius: 0.5vw !important;
                border-width: 0.1vw !important;
            }

            @media screen and (max-width: 576px) {
                height: 45px !important;
            }

            &::placeholder {
                color: $text-primary03 !important;
                font-size: 16px !important;
                font-weight: 400 !important;

                @media screen and (min-width: 2000px) {
                    font-size: 1.1vw !important;
                }
            }
        }

        .field_icon_img {
            position: absolute;
            left: 18px;
            top: 10px;

            @media screen and (min-width: 2000px) {
                top: 30%;
                left: 4%;
            }

            svg,
            img {
                width: 20px;
                height: 20px;
                color: #9a9da3 !important;

                @media screen and (min-width: 2000px) {
                    width: 1.5vw !important;
                    height: 1.5vw !important;
                }

                @media screen and (max-width: 576px) {
                    width: 18px !important;
                    height: 18px !important;
                }
            }
        }
    }
}

.global-padding {
    padding: 150px 0px;

    @media screen and (max-width: 991px) {
        padding: 70px 0px;
    }

    @media screen and (max-width: 568px) {
        padding: 50px 0px;
    }

    @media screen and (min-width: 2000px) {
        padding: 8vw 0px;
    }
}


@media screen and (max-width: 1500px) {
    br {
        display: none !important;
    }
}