@import"https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap";

@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');

h1,
h2,
h3,
h4,
h5,
h6 {
    /* color: #15295A; */
    /* font-family: "Archivo", sans-serif;
    margin-bottom: 0px !important;
    font-weight: 700 !important; */
}

p {
    color: #3b485c;
    font-size: 18px;
}

a {
    color: #15295A ;
    text-decoration: none !important;
}

p,
a,
span,
b,
small,
strong,
button,
input,
optgroup,
select,
textarea,
label {
    font-family: "Archivo", sans-serif;
    margin-bottom: 0px;
}

/* .logo-img {
    width: 200px;
    height: 50px;
} */

@import url(https://site-assets.fontawesome.com/releases/v6.1.1/css/all.css);

/* body {
    background-color: #f8fafc;
    padding: 0px;
    margin: 0px;
    overflow-x: hidden;
    /* position: relative; 
} */

/* .hero_Background_Gradient {
    position: absolute;
    width: 100vw;
    height: 447px;
    top: 311px;
    background: linear-gradient(180deg, #bae2ff, #e5d9ff 85.77%);
    filter: blur(100px);
    z-index: -1;
} */

.blue-btn {
    background-color: rgb(0, 122, 255);
    line-height: 18px;
    letter-spacing: 0px;
    font-weight: 700;
    font-size: 16px;
    border-color: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 8px;
    padding: 15px 20px;
    color: rgb(255, 255, 255);
    text-decoration: none;
    white-space: nowrap;
}

.gray-btn {
    background-color: #15295a0d;
    line-height: 18px;
    letter-spacing: 0px;
    font-weight: 700;
    font-size: 16px;
    border-color: #15295a0d;
    border-style: none;
    border-radius: 8px;
    padding: 15px 20px;
    color: #15295a;
    text-decoration: none;
    white-space: nowrap;
}

.white-btn {
    background-color: #fff;
    line-height: 18px;
    letter-spacing: 0px;
    font-weight: 700;
    font-size: 16px;
    border-color: #15295a0d;
    border-style: none;
    border-radius: 8px;
    padding: 15px 20px;
    color: #15295a;
    text-decoration: none;
    white-space: nowrap;
}



.Blue_box {
    background-color: #6980f0;
    padding: 100px;
}

.Blue_box .bg-grey_gradiant p,
.Blue_box .bg-grey_gradiant h1,
.Blue_box .bg-grey_gradiant h2,
.Blue_box .bg-grey_gradiant h3,
.Blue_box .bg-grey_gradiant h4,
.Blue_box .bg-grey_gradiant h5,
.Blue_box .bg-grey_gradiant h6,
.Blue_box .bg-grey_gradiant i {
    color: #fff;
}

.bg-grey_gradiant {
    background-color: #ffffff66;
    padding: 48px 32px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    min-height: 500px;
}

.white_box_gradiant {
    background-image: linear-gradient(180deg, #d6dce2, #4a7eff);
    padding: 5px;
    border-radius: 10px;
}

.white_box {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.bundleSubHeading {
    color: #285fe8;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
}


.image_box_gradiant {
    background-image: linear-gradient(180deg, #98c9ff, #ecaaff);
    padding: 3px;
    border-radius: 10px;
}

.image_box {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.image_box img {
    width: 80px;
    height: 80px;
}

.images_flex {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    padding: 30px 0px;
}

.images_flex p {
    font-size: 30px;
    color: #285fe8;
    font-weight: 600;
}

.listing_box p {
    font-size: 16px;
}

.pricing_dollars del {
    color: #c9e3fe;
    font-size: 20px;
}

.pricing_dollars h4 {
    font-size: 48px;
}

.navbar-light .navbar-toggler-icon {
    /* background-image: url(/images/toggle.png); */
}

.navbar-toggler {
    border-color: #000 !important;
    padding: 4px 6px;
}

.pricing_listing {
    border-top: 1px solid #e5e7eb;
    padding-top: 30px;
}

.pricing_listing p {
    font-size: 16px;
}

.white_box_trick {
    background-color: #fff;
    padding: 56px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.img-absolute_box {
    position: absolute;
    top: -80px;
}

.Listing_tricks {
    padding-top: 300px;
}

.Listing_tricks p {
    font-size: 16px;
}

.icon_img {
    width: 32px;
    height: 32px;
}

.blue_gradiant_box {
    background: linear-gradient(90deg, #1d2c53 3%, #28314e 96%);
    padding: 70px;
}

.blue_gradiant_card {
    background-color: #fff;
    border-left: 8px solid #3360d0;
    padding: 30px;
    border-radius: 10px;
}

.blue_gradiant_card p {
    font-size: 16px;
    padding-top: 10px;
}

.plan-card {
    background: linear-gradient(90deg, #1d2c53 3%, #28314e 96%);
    text-align: left;
    padding: 40px 40px;
    border-radius: 32px;
    height: 100%;
    position: relative;
    transition: all .3s ease-in-out;
    border: 1px solid transparent;
}

.plan-card .title {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.plan-card .title .icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: hsla(0, 0%, 100%, .122);
    border: 1px solid;
    border-image-source: linear-gradient(180deg, hsla(0, 0%, 100%, .16), hsla(0, 0%, 100%, .02));
    position: relative;
    transition: all .3s ease-in-out;
}

.plan-card .title .icon::after {
    content: "";
    display: block;
    background: transparent;
    width: 20px;
    height: 20px;
    border: 4px solid #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    transition: all .3s ease-in-out;
}

.plan-card .title .icon_center {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid transparent;
    background: linear-gradient(180deg, #2b61ec, #0047fc) !important;
    box-shadow:0px 3px 15px #3360d0;
    position: relative;
    transition: all .3s ease-in-out;
}

.plan-card .title .icon_center::after {
    content: "";
    display: block;
    background: transparent;
    width: 20px;
    height: 20px;
    border: 4px solid #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    transition: all .3s ease-in-out;
}

.plan-card .text {
    margin: 24px 0 32px;
}

.plan-card .text strong {
    font-size: 20px;
    display: block;
    margin: 0 0 4px;
    font-weight: 500;
}

.plan-card .text p {
    font-size: 14px;
}

.white {
    color: #fff !important;
}

.get-started-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, hsla(0, 0%, 100%, .08), hsla(0, 0%, 100%, 0));
    color: #fff !important;
    border: 1px solid hsla(0, 0%, 100%, .102);
    box-shadow: none;
    margin: 40px 0;
    font-weight: 500;
    padding: 12px 0px;
    min-width: 100px;
    min-height: auto;
    cursor: pointer;
    border-radius: 10px;
    text-decoration: none;
    line-height: 19.36px;
    transition: all .3s ease-in-out;
    z-index: 2;
    /* box-shadow: inset 0 0 2px 1px hsla(0, 0%, 100%, .251); */
    gap: 10px;
    text-decoration: none !important;
}

.get-started-btn_center{
    width: 100%;
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, #2b61ec, #0047fc) !important;
    color: #fff !important;
    box-shadow: none;
    margin: 40px 0;
    font-weight: 500;
    padding: 12px 0px;
    min-width: 100px;
    min-height: auto;
    cursor: pointer;
    border-radius: 10px;
    text-decoration: none;
    line-height: 19.36px;
    box-shadow:0px 3px 15px #3360d0;
    /* box-shadow: 0 0 10px 3px #3360d0; */
    /* box-shadow: inset 0 0 2px 1px #3360d0; */  
    transition: all .3s ease-in-out;
    z-index: 2;
    gap: 10px;
    text-decoration: none !important;
    border: 2px solid transparent !important;
}
.plan-card .features-main-wrapper ul.features-list li {
    display: grid;
    grid-template-columns: 14px auto;
    gap: 8px;
    padding-bottom: 16px !important;
}

.plan-card .features-main-wrapper ul.features-list {
    margin: 18px 0px 0px;
    padding-left: 0px !important;
}

.plan-card .features-main-wrapper ul.features-list li span {
    font-size: 14px;
    color: hsla(0, 0%, 100%, .8);
    line-height: 18px;
    display: inline-block;
}

.plan-card sub {
    font-size: 15px;
    bottom: -4px !important;
    opacity: 0.8;
}

.plan-card .price{
    border-bottom: 1px solid hsla(0,0%,100%,.239);
}

.plan-card .features-main-wrapper{
    padding-top: 30px;
}

.footer{
    border-top:1px solid #4c5b721a;
}

.footer_flex_box{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer_icon_img{
    width: 60px !important;
}

.footer_content p{
    text-align: center;
    font-size: 14px;
}

.footer a{
    color: #000000a6 !important;
}

.navbar-nav .nav-item .blue-btn{
    color: #fff !important;
    padding: 13px 20px !important;
}

/* .img_shadow{
    border-radius: 5px;
    border-color: rgba(255, 255, 255, 0.1);
    box-shadow: 
        0px 221px 62px 0px rgba(221, 221, 221, 0.01), 
        0px 142px 57px 0px rgba(246, 247, 250, 0.05), 
        0px 80px 48px 0px rgba(173, 173, 173, 0.16), 
        0px 35px 35px 0px rgba(229, 230, 231, 0.27), 
        0px 9px 19px 0px rgba(210, 210, 211, 0.31);
} */

/* .login-blue-btn{
    background-color: transparent;
    border: 2px solid #007aff !important;
    padding: 8px 20px !important;
    border-radius: 5px;
} */

.Process-cards {
    /* border: 1px solid #f2f2f4;
    background: #ffffff;
    -webkit-box-shadow: 4.871px 34.659px 30px 0px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 4.871px 34.659px 30px 0px rgba(0, 0, 0, 0.06);
    box-shadow: 4.871px 34.659px 30px 0px rgba(0, 0, 0, 0.06);
    z-index: 1;
    display: block;
    padding: 0 30px 30px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    height: 100%; */
}

/* .login_cards{
    padding-top: 30px;
    max-width: 600px;
    width: 90%;
    margin: auto;
} */

/* .login_cards label{
    padding-bottom: 5px;
    color: #5E7290;
    font-weight: 500;
}

.login_cards p a{
    text-decoration: none;
    color: #007BFC;
}

.login_cards .Or_content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.login_cards .Or_content hr{
    width: 50%;
}

.login_cards .google-btn{
    background-color: transparent !important;
    border: 1px solid #00000049 !important;
    margin-top: 10px;
    color: #000000d3 !important;
    border-radius: 8px !important;
}

.login_cards .google-btn .icon_box{
    background-color: #000000d3 !important;
    color: #eeeeee !important;
}

.login_cards .blue-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 8px !important;
}

.login_cards .blue-btn .icon_box{
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background-color: #eeeeee;
    color: #007BFC;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
} */

/* .input-form-box {
    outline: none;
    border: 1px solid #eeeeee;
    padding: 10px 15px;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    width: 100%;
}

.input-form-box:focus {
    border-color: #007BFC;
} */

.carousel_box{
    padding-top: 200px;
}

.images_carousel{
    opacity: 0.5;
}
.images_carousel_border{
    border: 2px solid #fff;
    border-radius: 50px;
}

.img-absolute{
    position: absolute;
    top: -50px;
    width: 70%;
    right: 0px;
}
@media screen and (max-width: 992px) {
    .bg-img {
        background-image: none;
        background-color: #0d1e67;
        height: auto;
        padding-bottom: 50px;
    }

    .sections-margin-home,
    .sections-margin {
        margin-top: 50px;
    }

    .nav-pills {
        flex-direction: row;
        width: fit-content;
        gap: 10px;
    }

    .footer ul li {
        list-style: none;
    }

    .Storage-service {
        white-space: wrap;
    }

    .input-form input {
        min-width: 0px;
    }

    .hover-cards:hover {
        margin-top: -30px;
    }
}


.download_heading{
    text-align: center;
    padding: 100px 0px;
    text-decoration: underline;
}
@media screen and (max-width: 576px) {
    .Img-blog img {
        width: 100%;
        object-fit: contain;
    }
}