$text-primary04: #383838;
$offwhite: #F6F6F6;

.model-bookmark {
    .modal-dialog {
        .modal-content {
            p {
                margin-bottom: 0px !important;
            }

            .modal-header {
                .modal-title {
                    font-family: Inter;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 19.36px;
                    text-align: left;
                    color: #21367F;
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    img,
                    svg {
                        width: 17px;
                        height: 19px;
                    }
                }

                .btn-close {
                    width: 8.49px;
                    height: 8.49px;
                }

            }

            .model-extension-body {
                .body-heading {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 5px;
                    margin-bottom: 13px;

                    p {
                        color: $text-primary04;
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 16.94px;
                        text-align: left;
                    }

                    button {
                        background: $offwhite;
                        border-radius: 8.47px;
                        color: $text-primary04;
                        border: 1px solid $offwhite;
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16.94px;
                        text-align: left;
                        padding: 10px 20px;
                        display: flex;
                        align-items: center;
                        gap: 8px;
                    }
                }
            }
        }
    }
}

.body-listing {
    .listing-box {
        padding: 7px 14px;
        background: $offwhite;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        &:last-of-type {
            margin-bottom: 0px;
        }

        &:hover {
            box-shadow: 0px 4.84px 4.96px 0px #0A102517;
        }

        .names-heading {
            display: flex;
            align-items: center;
            gap: 12px;

            .icon-box {
                background: #D9D9D9;
                border-radius: 50%;
                font-family: Inter;
                font-size: 14px;
                font-weight: 600;
                line-height: 16.94px;
                color: $text-primary04;
                width: 26px;
                height: 26px;
                min-width: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .badge-box {
                display: flex;
                align-items: center;
                gap: 4px;

                p {
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.94px;
                    color: $text-primary04;

                }

                badge {
                    background-color: #848484;
                    color: #D9D9D9;
                    border-radius: 15.38px;
                    padding: 2px 6px;
                    font-family: Inter;
                    font-size: 9.71px;
                    font-weight: 700;
                }

                .input-form {
                    box-shadow: 0px 2px 4px 0px #00000017;
                    border: none;
                    outline: none;
                    padding: 4px 5px;
                    border-radius: 3px;
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.94px;
                    color: $text-primary04;
                    width: 237px;
                }
            }
        }

        .edit-headings {
            display: flex;

            button {
                background-color: transparent;
                border: none;
                font-family: Inter;
                font-size: 12px;
                font-weight: 500;
                line-height: 14.52px;
                color: #848484;
                display: flex;
                gap: 1px;
                align-items: center;

                &.save-btn {
                    color: $text-primary04;
                }

                span {
                    display: none;
                }

                &:hover {
                    svg {
                        color: #383838;
                    }

                    span {
                        display: block;
                    }

                }

                svg {
                    width: 17px;
                    height: 17px;
                    color: #848484;
                    margin-right: 4px;
                }
            }
        }
    }
}

.deafult-model-bookmark {
    .modal-dialog {
        .modal-content {
            p {
                margin-bottom: 0px !important;
            }

            .modal-header {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .modal-title {
                    font-family: Inter;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 19.36px;
                    text-align: left;
                    color: $text-primary04;
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    img,
                    svg {
                        width: 17px;
                        height: 19px;
                    }
                }

                .header-searchbox {
                    background: #F6F6F6;
                    border-radius: 7px;
                    display: flex;
                    align-items: center;
                    height: 40px;
                    min-width: 185px;
                    position: relative;

                    .input-form {
                        border: none;
                        background-color: transparent;
                        padding: 0px 40px 0px 9px;
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        color: #909090;
                        outline: none;
                    }

                    button {
                        border: none;
                        background-color: transparent;
                        position: absolute;
                        right: 8px;
                        top: 6px;
                        padding: 0px;

                        svg {
                            color: #979797;
                            width: 15px;
                            height: 15px;
                        }
                    }
                }

            }

            .modal-body {
                max-height: 500px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 6px;

                }


                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }


                &::-webkit-scrollbar-thumb {
                    background: #D9D9D9;
                    border-radius: 5px;
                }


                &::-webkit-scrollbar-thumb:hover {
                    background: #D9D9D9;
                }
            }

        }
    }
}

.default-body-listing {
    .listing-box {
        background: #F6F6F6;
        border-radius: 6px;
        padding: 14px 9px;
        margin-bottom: 12px;


        &:hover {
            background-color: #ffffff;
            box-shadow: 0px 4.84px 4.96px 0px #0A102517;

            .names-heading-flexbox .button-box {
                display: block;
            }
        }

        &:last-of-type {
            margin-bottom: 0px;
        }

        .names-heading-flexbox {
            display: flex;
            justify-content: space-between;

            .names-heading {
                display: flex;
                align-items: center;
                gap: 8px;
                padding-bottom: 7px;

                .icon-box {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    object-fit: cover;

                    img {
                        width: 100%;
                        border-radius: 50%;
                    }
                }

                .name-box {
                    h6 {
                        font-family: Inter;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20px;
                        color: $text-primary04;
                    }

                    p {
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: -0.5px;
                        color: #808187;
                    }
                }
            }

            .button-box {
                // display: none;

                button {
                    background-color: transparent;
                    border: none;

                    svg {
                        width: 15px;
                        height: 15px;
                        color: #868686;
                    }
                }
            }
        }

        p {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #383838;
        }

        .card-bottom {
            margin-top: 17px;

            button {
                border: none;
                background-color: transparent;
                font-family: Inter;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                color: #808187;

                svg {
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                }
            }
        }
    }
}

.editor_modal-default {
    position: absolute;
    z-index: 99999999999;
    right: 0;
    width: 100%;
    max-width: 90%;

    .model_reminder {
        position: absolute;
        right: 0;
        margin-left: auto;
        margin-top: 10px;
        border-radius: 5px !important;
        max-width: 264px;
        border: none;
        
        .save-button {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 23px auto 0px;
            max-width: max-content;
            width: 100%;
            padding: 8px 40px;
            background: #363EAB1C !important;
            color: #21367F !important;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            border: none;
            font-weight: 700;
            font-size: 10px !important;
            font-family: Inter;
        }

        .modal-body {
            padding: 0px !important;
            box-shadow: 0px 4px 6px 0px #1F29370D;
            box-shadow: 0px 10px 15px 0px #1F29371A;
            border: none;
            
            .modal-heading-draft{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 12px !important;
                border-bottom: 0.3px solid #3333331F;
                .content-heading{
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    h6{
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 16px;
                        color: #21367F;    
                    }  
                    img,svg{
                        width: 15px;
                        height: 15px;
                    }    
                }
                .button-box{
                    display: flex;
                    align-items: center;
                    gap: 7px;
                    button{
                        background-color: transparent;
                        border: none;       
                        width: 15px;
                        height: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        svg{
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
            }
            .model_flex_box {
                padding: 10px 10px 10px !important;

                .textarea-box {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    align-items: flex-start;
                }
            }

            .modal-heading {
                padding: 15px 10px 5px 10px !important;
                border-bottom: 0.3px solid #3333331F;
                h6 {
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16px;
                    color: #383838;
                    text-align: center;
                }
            }

            label {
                margin-right: 5px;
                font-weight: bold;
                color: #383838;
                font-size: 10px;
                text-wrap: nowrap !important;
            }

            textarea {
                width: 100%;
                max-width: 240px;
                padding: 10px;
                border-radius: 4px;
                resize: none;
                background: #F3EEEE9C;
                border-radius: 6px !important;
                font-family: Inter;
                font-size: 10px;
                font-weight: 400;
                line-height: 12.1px;
                text-align: left;
                outline: none;
                border: 1px solid transparent;

                &::placeholder {
                    color: #979797;
                    font-size: 10px !important;
                }

                &:focus {
                    outline: none;
                    border: 1px solid #21367F;
                }
            }


            p {
                color: red;
                font-size: 10px;
            }

        }
    }

    .reminder-options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        padding-top: 8px;

        .remind-in {
            display: flex;
            gap: 6px;
            align-items: center;
            width: 100%;

            .input_position {
                position: relative;
                width: 54px;

                ul {
                    position: absolute;
                    right: 3px;
                    top: 0px;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 0px !important;
                    height: 100%;
                    background-color: rgba(243, 238, 238, 0.6117647059);
                    background: rgba(243, 238, 238, 0.6117647059);

                    li {
                        background-color: transparent;
                        color: #383838 !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 48%;

                        svg {
                            width: 6px !important;
                            height: 6px !important;
                            background-color: rgba(243, 238, 238, 0.76);
                        }
                    }
                }
            }

            input {
                width: 54px;
                height: 28px;
                font-size: 10px;
                border-radius: 6px;
                background: #F3EEEE9C;
                border: none;
                padding: 0 0 0 5px;

                &:focus {
                    outline: none;
                }
            }

            .dropdown {
                width: 100%;
                border-radius: 6px;

                .dropdown-toggle {
                    width: 100%;
                    // max-width: 157px;
                    padding: 7px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 12px;
                    background: #F3EEEE9C;
                    height: 8vw;
                    font-weight: 500;
                    color: #383838;

                    &:after {
                        display: inline-block;
                        margin-left: 0.255em;
                        vertical-align: 0.255em;
                        content: "";
                        width: 1.5vw;
                        height: 1.5vw;
                        border-left: .5vw solid;
                        border-top: .5vw solid;
                        transform: rotate(225deg);
                        border-right: none;
                        border-bottom: none;
                        position: absolute;
                        right: 12px;
                        top: 10px;
                    }

                    &:hover {
                        background: none;
                        outline: 1px solid #21367F;

                    }

                    &.btn.show {
                        background: transparent;
                        outline: 1px solid #21367F;
                    }
                }

                .dropdown-menu {
                    background-color: #FCFBFB;
                    padding: 0px !important;

                    .dropdown-item {
                        font-size: 12px;
                        font-weight: 400;
                        border-bottom: 0.3px solid #3333331F;
                        padding: 8px 11px;

                        &:last-of-type {
                            border: none !important;
                        }

                        &:hover {
                            background: #21367F;
                            color: white;
                        }
                    }
                }

            }

            select {
                flex-grow: 1;
                padding: 5px;
                border-radius: 4px;
                border: none;
                height: 28px;
                background: #F3EEEE9C;
                font-size: 10px;
                font-family: Inter;

                &:focus {
                    outline: 1px solid #21367F;
                }

                option {
                    &:hover {
                        background-color: #21367F;
                    }
                }

            }
        }
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}

.draft-model {
    .modal-header {
        .modal-title {
            font-family: Inter;
            font-size: 16px;
            font-weight: 700;
            line-height: 19.36px;
            text-align: left;
            color: #21367F;
            display: flex;
            align-items: center;
            gap: 12px;

            img,
            svg {
                width: 17px;
                height: 19px;
            }
        }
    }

    .modal-body {
        max-height: 480px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 6px;

        }


        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }


        &::-webkit-scrollbar-thumb {
            background: #D9D9D9;
            border-radius: 5px;
        }


        &::-webkit-scrollbar-thumb:hover {
            background: #D9D9D9;
        }

        .default-body-listing {
            .listing-box {
                background: #ffffff;
                box-shadow: 0px 4.84px 4.96px 0px #0A102517;
            }
        }

        .body-heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 5px;
            margin-bottom: 13px;

            p {
                color: $text-primary04;
                font-family: Inter;
                font-size: 14px;
                font-weight: 700;
                line-height: 16.94px;
                text-align: left;
            }

            button {
                background: $offwhite;
                border-radius: 8.47px;
                color: $text-primary04;
                border: 1px solid $offwhite;
                font-family: Inter;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
                text-align: left;
                padding: 10px 20px;
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }

        .body-listing {
            .selection-box {
                display: flex;
                align-items: center;
                gap: 8px;
                margin-bottom: 10px;

                &:last-of-type {
                    margin-bottom: 0px;
                }

                .form-check-input {
                    box-shadow: none;
                    border-color: #909090;
                    width: 12px;
                    height: 12px;

                    &:checked {
                        border-color: #363EAB !important;
                        background-color: #363EAB !important;
                    }
                }

                .listing-box {
                    flex-grow: 1;
                }
            }

        }
    }

    .modal-footer {
        padding: 8px;
        justify-content: center;

        button {
            margin: 0px;
            box-shadow: 0px 4px 4.1px 0px #0A102517;
            box-shadow: 0px -4px 14.9px -2px #555CC1 inset;
            background: #363EAB;
            border-color: #363EAB;
            font-family: Inter;
            font-size: 16px;
            font-weight: 700;
            line-height: 19.36px;
            border-radius: 5px;
            width: 190px;
            opacity: 0.5;
        }
    }
}

.bookmark-save-model {
    .modal-header {
        .modal-title {
            font-family: Inter;
            font-size: 16px;
            font-weight: 700;
            line-height: 19.36px;
            text-align: left;
            color: #21367F;
            display: flex;
            align-items: center;
            gap: 12px;

            img,
            svg {
                width: 17px;
                height: 19px;
            }
        }
    }

    .modal-body {
        padding: 16px 16px 30px 16px;

        .model-extension-save-body {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 10px;

            .close-btn {
                background: #F6F6F6;
                border-radius: 5px;
                width: 190px;
                border-color: transparent;
                font-family: Inter;
                font-size: 16px;
                font-weight: 700;
                line-height: 19.36px;
                color: $text-primary04;
                padding: 4px;
            }
        }
    }

}