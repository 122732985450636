// $inter: "Inter", sans-serif;
@import "Global";

.navbar_section {
    .navbar {
        min-height: 5vw !important;

        .navbar-brand {
            .logo-img {
                width: 189px;
                height: 37px;

                @media screen and (min-width: 2000px) {
                    width: 12vw !important;
                    height: auto !important;
                }
            }
        }

        .buttons-portion {
            gap: 14px;
            align-items: center;

            @media screen and (max-width: 1400px) and (min-width:992px) {
                gap: 10px;
            }

            // a{
            //     @media screen and (max-width: 1200px) and (min-width:992px) {
            //         font-size: 12px !important;
            //         padding-right: 6px;
            //      } 
            // }
            a{
                text-wrap: nowrap;
            }

            &.link {
                padding-right: 5vw !important;

                @media screen and (max-width: 1400px) and (min-width:992px) {
                   padding-right: 3vw !important;
                }

                @media screen and (max-width: 991px) {
                    padding-right: 0px !important;
                }
            }

            @media screen and (min-width: 2000px) {
                gap: 2vw;
            }
        }

        .white-bg-btn {
            button {
                width: 209px;
                height: 40px;

                @media screen and (min-width: 2000px) {
                    width: auto !important;
                    height: auto !important;
                    border-radius: 0.5vw;
                }

                @media screen and (max-width: 1200px) and (min-width:992px) {
                    width: 180px;
                }
            }
        }


        .profile-dropdown {
            .dropdown-toggle {
                background: linear-gradient(108.72deg, $darkblue2 31.47%, #1F7DDC 97.11%) !important;
                color: $white;
                border: none !important;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;

                &::after {
                    display: none !important;
                }

                @media screen and (min-width: 2000px) {
                    width: 2.5vw !important;
                    height: 2.5vw !important;

                    svg {
                        width: 1vw !important;
                        height: 1vw;
                    }
                }
            }

            .dropdown-menu {
                padding: 0px !important;
                right: 0px !important;
                left: auto !important;

                @media screen and (min-width: 2000px) {
                    border-radius: 0.5vw;
                }

                .dropdown-item {
                    border-bottom: 1px solid #cccccc;
                    padding-block: 10px;

                    @media screen and (min-width: 2000px) {
                        padding: 0.2vw 4vw 0.2vw 1vw !important;
                    }

                    &:first-of-type {
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;

                        @media screen and (min-width: 2000px) {
                            border-top-left-radius: 0.5vw;
                            border-top-right-radius: 0.5vw;
                        }
                    }

                    &:last-of-type {
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                        border-bottom: 0px !important;

                        @media screen and (min-width: 2000px) {
                            border-bottom-left-radius: 0.5vw;
                            border-bottom-right-radius: 0.5vw;
                        }
                    }
                }
            }
        }
    }
}

.home_page_mian_div {
    .bg-img-div {
        background-image: url("../public/images/background-mask.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        &.orange {
            background-image: none;
            background: #D99B181C;

            .button_content_box {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 43px;

                @media screen and (min-width: 2000px) {
                    margin-top: 3vw;
                }
            }
        }
    }

    .optimize_crm {
        padding: 100px 0px;

        @media screen and (min-width: 2000px) {
            padding: 8vw 0px 6vw 0px;
        }

        @media screen and (max-width: 576px) {
            padding: 60px 0px;
        }

        .optimize_crm_content {
            width: 85%;

            @media screen and (min-width: 2000px) {
                p {
                    padding: 1vw 0px 2.5vw 0px !important;
                }
            }

            @media screen and (max-width: 991px) {
                width: 100%;
            }

            .button_content_box {
                .gradiant-bg-btn {
                    @media screen and (max-width: 991px) {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    button {
                        width: 197px;
                        height: 50px !important;

                        @media screen and (min-width: 2000px) {
                            width: auto !important;
                            height: auto !important;
                        }
                    }
                }

                p {
                    font-family: $inter;
                    font-size: 12px !important;
                    font-weight: 400;
                    line-height: 20px;
                    color: #1F7DDC;

                    @media screen and (min-width: 2000px) {
                        font-size: 0.8vw !important;
                        padding: 0.2vw 0px 0px 0px !important;
                    }
                }
            }

            h1 {
                font-weight: 900 !important;

                @media screen and (max-width: 768px) {
                    font-weight: 800 !important;
                    font-size: 38px !important;
                    line-height: 50px !important;
                }

                @media screen and (max-width: 576px) {
                    font-size: 32px !important;
                    line-height: 45px !important;
                }
            }
        }
    }

    .bg-img-skool-section {
        background-image: url("../public/images/skool-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        &.transparent{
            background-image: none;
        }

        .skool_section {
            .global-padding {
                border-bottom: 1px solid #DBEAFF;

                h2 {
                    text-align: center;
                    width: 80%;
                    margin: auto;

                    @media screen and (min-width: 2000px) {
                        width: 90%;
                    }

                    @media screen and (max-width: 576px) {
                        width: 100% !important;
                    }

                    span {
                        background: #F8FDFF;
                        // border-radius: 8px;
                        overflow: hidden;
                        // border: 1px solid $darkblue2;
                        // padding: 0px 10px 5px 5px;
                        // box-shadow: 0px 4px 4px 0px $darkblue221;

                        @media screen and (min-width: 2000px) {
                            // border-radius: 0.5vw !important;
                            // padding: 0px 1.5vw !important;
                            // border-width: 0.1vw;
                        }

                        img {
                            width: 126px;
                            height: 55px;

                            @media screen and (max-width: 568px) {
                                width: 100px;
                                height: auto;
                            }

                            @media screen and (min-width: 2000px) {
                                width: 9vw;
                                height: 4vw;
                            }
                        }
                    }


                }
            }
        }

        .cards_section {
            .heading_box {
                text-align: center;

                p {
                    padding-top: 10px;

                    @media screen and (min-width: 2000px) {
                        padding-top: 1.6vw;
                    }
                }
            }

            .cards_grid_section {
                padding-top: 100px;

                @media screen and (max-width: 576px) {
                    padding-top: 40px;
                }

                .responsive-padding {
                    @media screen and (min-width: 2000px) {
                        padding-right: 1vw;

                        &:last-of-type {
                            padding-right: 0px;
                        }
                    }
                }

                @media screen and (min-width: 2000px) {
                    padding-top: 6vw;
                }

                .card {
                    background: $white;
                    border: none;
                    padding: 28px;
                    height: 100%;

                    @media screen and (min-width: 2000px) {
                        padding: 2vw 1.5vw;
                        border-radius: 0.5vw;
                    }

                    h6 {
                        padding: 30px 0px;

                        @media screen and (max-width: 576px) {
                            padding: 15px 0px !important;
                        }

                        @media screen and (min-width: 2000px) {
                            padding: 2vw 0px;
                        }
                    }

                    .icon_box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 56px;
                        height: 56px;
                        border-radius: 8px;
                        background: linear-gradient(315.55deg, #EF4444 0%, #FF8989 92.87%);

                        @media screen and (min-width: 2000px) {
                            width: 3.5vw !important;
                            height: 3.5vw !important;
                            border-radius: 0.5vw !important;

                            svg,
                            img {
                                width: 1.5vw;
                                height: 1.5vw;
                            }
                        }

                        &.green {
                            background: linear-gradient(314.44deg, #14B8A6 0%, #48DFCE 91.08%);
                        }

                        &.yellow {
                            background: linear-gradient(314.44deg, #F59E0B 0%, #FFD285 91.08%);
                        }

                        &.blue {
                            background: linear-gradient(318.24deg, #3B82F6 0%, #86B4FF 94.95%);
                        }

                        img,
                        svg {
                            width: 20px;
                            height: 20px;
                            color: $white !important;
                            opacity: 0.8;

                            @media screen and (min-width: 2000px) {
                                width: 1.3vw;
                                height: 1.3vw;
                            }
                        }
                    }

                    .icon_img {
                        width: 56px;
                        height: 56px;
                        border-radius: 8px;
                        overflow: hidden;
                        position: relative;

                        @media screen and (min-width: 2000px) {
                            width: 3.5vw !important;
                            height: 3.5vw !important;
                            border-radius: 0.5vw !important;
                        }

                        @media screen and (max-width: 576px) {
                            width: 40px !important;
                            height: 40px !important;
                        }

                        img {
                            width: 100%;
                        }
                    }
                }

                .gradiant-bg-btn {
                    padding-top: 90px;

                    @media screen and (min-width: 2000px) {
                        padding-top: 6vw;
                    }

                    @media screen and (max-width: 576px) {
                        padding-top: 40px;
                    }

                    button {
                        margin: auto;
                        width: 340px;
                        height: 50px;

                        @media screen and (min-width: 2000px) {
                            width: auto !important;
                            height: auto !important;
                        }
                    }
                }
            }
        }
    }

    .grid_sections {
        .grid_content {
            @media screen and (min-width: 2000px) {
                p {
                    padding: 1.5vw 0px !important;
                }
            }

            .flex_grid_content_box {
                display: flex;
                flex-direction: column;
                gap: 15px;
                margin-bottom: 15px;

                @media screen and (min-width: 2000px) {
                    gap: 1.3vw;
                    margin-bottom: 1.3vw;
                }

                .grid_listbox_flexbox {
                    background-color: #F2F8FF;
                    border-radius: 8px;
                    padding: 12px;
                    display: flex;
                    gap: 20px;

                    @media screen and (max-width: 576px) {
                        gap: 15px;
                    }

                    @media screen and (min-width: 2000px) {
                        padding: 1.5vw 1.2vw;
                        gap: 1vw;
                        border-radius: 0.6vw;

                        p {
                            padding: 0px !important;
                        }
                    }

                    .icon_box {
                        background-color: #DDEAF8B2;
                        min-width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 5px;

                        @media screen and (max-width: 576px) {
                            min-width: 32px;
                            height: 32px;
                        }

                        @media screen and (min-width: 2000px) {
                            min-width: 2vw;
                            height: 2vw;
                        }

                        p {
                            font-weight: 500 !important;
                        }
                    }

                    .grid_flex_content {
                        p {
                            color: $darkblue2;
                            font-weight: 700;

                            @media screen and (min-width: 2000px) {
                                padding-bottom: 0.5vw !important;
                            }
                        }

                        span {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 30px;
                            text-align: left;
                            color: $text-primary02 !important;

                            @media screen and (min-width: 2000px) {
                                font-size: 1.1vw;
                                line-height: normal;
                                letter-spacing: 0.02vw;
                            }

                            @media screen and (max-width: 576px) {
                                font-size: 14px;
                                line-height: 20px;
                            }
                        }
                    }
                }
            }


            .white-bg-btn {
                button {
                    padding: 15px 20px;
                    color: $darkblue2 !important;
                }
            }
        }
    }
}

.pricing_page {
    background: linear-gradient(82.02deg, $darkblue2 12.85%, #1F7DDC 87.7%);

    .pricing-padding {
        padding: 82px 0px 117px 0px;

        @media screen and (max-width: 576px) {
            padding: 50px 0px !important;
        }

        @media screen and (min-width: 2000px) {
            padding: 8vw 0px !important;
        }

        .heading_box {
            text-align: center;

            h2 {
                color: $white !important;
            }

            p {
                color: #CFD3D7;
            }
        }

        .pricing_toggle_flex {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px !important;
            padding: 50px 0px !important;

            .pricing_heading_flex {
                display: flex;
                align-items: center;
                gap: 10px;

                @media screen and (max-width: 576px) {
                    gap: 5px !important;
                }

                @media screen and (min-width: 2000px) {
                    gap: 0.2vw !important;
                }
            }

            @media screen and (max-width: 576px) {
                padding: 30px 0px !important;
                gap: 8px !important;
            }

            @media screen and (min-width: 2000px) {
                padding: 4vw 0px !important;
                gap: 2vw !important;
            }

            h6 {
                color: $white;

                span {
                    font-size: 10px;
                    font-weight: 700;
                    line-height: 14.52px;
                    letter-spacing: 1.625px;
                    text-align: center;
                    background-color: #4274bb;
                    padding: 8px 12px;
                    border-radius: 50px;
                    margin-left: 12px;

                    @media screen and (min-width: 2000px) {
                        font-size: 0.7vw;
                        line-height: normal;
                        padding: 0.4vw 0.5vw;
                    }
                }
            }

            .checkbox-apple {
                position: relative;
                width: 55px;
                height: 27px;
                margin: 10px 0px !important;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                @media screen and (min-width: 2000px) {
                    width: 4vw;
                    height: 2vw;
                    margin: 1vw 0 !important;
                }

                label {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 53px;
                    height: 28px;
                    border-radius: 50px;
                    background-color: #4274bb;
                    cursor: pointer;
                    transition: all 0.3s ease;

                    @media screen and (min-width: 2000px) {
                        width: 4vw;
                        height: 2.1vw;
                        margin: 0 !important;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        top: 3px;
                        left: 3px;
                        width: 22px;
                        height: 22px;
                        border-radius: 50%;
                        background-color: $white;
                        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
                        transition: all 0.3s ease;

                        @media screen and (min-width: 2000px) {
                            top: 0.225vw;
                            left: 0.225vw;
                            width: 1.65vw;
                            height: 1.65vw;
                        }
                    }
                }

                input[type="checkbox"] {
                    visibility: hidden;
                    position: absolute;
                }

                input[type="checkbox"]:checked+label {
                    // background: rgb(246, 187, 26);

                    &:after {
                        transform: translateX(25px);

                        @media screen and (min-width: 2000px) {
                            transform: translateX(1.875vw);
                        }
                    }
                }


                .yep {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 50px;
                    height: 25px;

                    @media screen and (min-width: 2000px) {
                        height: 3.5vw;
                        width: 1.85vw;
                    }
                }
            }

        }

        .padding_cards {
            padding-top: 100px;

            @media screen and (min-width: 2000px) {
                padding-top: 6vw;
            }

            @media screen and (max-width: 576px) {
                padding-top: 30px
            }

            .responsive-padding {
                @media screen and (min-width: 2000px) {
                    padding-right: 1vw;

                    &:last-of-type {
                        padding-right: 0px;
                    }
                }
            }

            .card {
                background-color: $white;
                border: 1px solid #E7E9ED;
                padding: 37px 49px;
                height: 100%;
                width: 93%;
                margin: auto;
                border-radius: 10px !important;

                @media screen and (min-width: 2000px) {
                    padding: 2vw 3vw;
                    border-radius: 1vw !important;
                }

                @media screen and (max-width: 768px) {
                    padding: 20px;
                    border-radius: 0.9vw;
                }

                .card_heading {

                    p {
                        font-size: 13px;
                        font-weight: 700;
                        line-height: 15.73px;
                        letter-spacing: 1.625px;
                        text-transform: uppercase;
                        color: $darkblue2;

                        @media screen and (min-width: 2000px) {
                            font-size: 0.8vw !important;
                            letter-spacing: 0.1vw !important;
                        }
                    }
                }

                .card_billing {
                    padding: 40px 0px 20px 0px;

                    @media screen and (max-width: 576px) {
                        padding: 20px 0px;
                    }

                    @media screen and (min-width: 2000px) {
                        padding: 3vw 0px 2vw 0px;
                    }

                    h1 {
                        color: $text-primary01 !important;
                        font-size: 60px !important;
                        font-weight: 700 !important;
                        line-height: 65px !important;
                        letter-spacing: -2px !important;
                        padding-block: 40px 10px;
                        line-height: 0px !important;

                        @media screen and (max-width: 576px) {
                            font-size: 45px !important;
                            padding-top: 20px;
                        }

                        @media screen and (min-width: 2000px) {
                            font-size: 4vw !important;
                            line-height: 4.5vw !important;
                            padding: 0px !important;
                        }

                        span {
                            font-size: 24px !important;
                            font-weight: 700 !important;
                            letter-spacing: -0.800000011920929px !important;
                            text-align: left !important;

                            @media screen and (min-width: 2000px) {
                                font-size: 2vw !important;
                            }
                        }

                        sub {
                            font-size: 17px;
                            font-weight: 400;
                            line-height: 29px;
                            letter-spacing: -0.09444444626569748px;

                            @media screen and (min-width: 2000px) {
                                font-size: 1.3vw !important;
                                line-height: auto !important;
                            }
                        }

                    }

                    p {
                        font-size: 15px !important;
                        font-weight: 400 !important;
                        line-height: 26px !important;
                        opacity: 0.8;
                        color: $text-primary01;

                        @media screen and (min-width: 2000px) {
                            font-size: 1.1vw !important;
                            line-height: normal !important;
                        }
                    }
                }

                .card_listing {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    height: 100%;

                    @media screen and (min-width: 2000px) {
                        gap: 1vw;
                    }

                    .card_list_flexbox {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        padding-bottom: 10px;

                        &:last-of-type {
                            padding-bottom: 0px;
                        }

                        p {
                            padding-bottom: 10px;

                            &:last-of-type {
                                padding-bottom: 0px !important;
                            }
                        }

                        @media screen and (min-width: 2000px) {
                            gap: 0.5vw;
                        }

                        .icon_box {
                            svg {
                                color: #68D585;
                                width: 17px;
                                height: 17px;

                                @media screen and (min-width: 2000px) {
                                    height: 1.3vw !important;
                                    width: 1.3vw !important;
                                }
                            }
                        }

                        p {
                            color: $text-primary01 !important;

                            @media screen and (min-width: 2000px) {
                                font-size: 1.1vw !important;
                            }
                        }
                    }
                }

                .card_button_content_box {
                    padding-top: 30px;

                    @media screen and (min-width: 2000px) {
                        padding-top: 2.5vw;
                    }

                    .white-bg-btn {
                        &.active {

                            button,
                            a {
                                background: linear-gradient(108.72deg, $darkblue2 31.47%, #1F7DDC 97.11%) !important;
                                color: $white !important;
                                border-radius: 6px !important;

                                @media screen and (min-width: 2000px) {
                                    border-radius: 0.5vw !important;
                                }
                            }
                        }

                        button,
                        a {
                            background: #EDEFF5 !important;
                            height: 50px;
                            width: 90%;
                            color: $darkblue2;
                            font-weight: 700 !important;

                            @media screen and (min-width: 2000px) {
                                height: 4vw !important;
                                width: 90% !important;
                            }

                            @media screen and (max-width: 768px) {
                                width: 100%;
                            }
                        }
                    }

                    p {
                        font-size: 15px;
                        font-weight: 400;
                        color: $text-primary01;
                        opacity: 0.7;

                        @media screen and (min-width: 2000px) {
                            font-size: 1.1vw !important;
                            padding-top: 0.5vw !important;
                        }
                    }
                }
            }
        }
    }
}

.footer_box {
    background-color: $darkblue2;

    .footer_upper_content {
        padding: 100px 0px 50px 0px;

        @media screen and (min-width: 2000px) {
            padding: 7vw 0px 4vw 0px !important;
        }

        @media screen and (max-width: 576px) {
            padding: 50px 0px;
        }

        .logo_column {
            @media screen and (max-width: 576px) {
                text-align: center;
            }

            .logo_img {
                width: 139px;
                height: 20px;

                @media screen and (min-width: 2000px) {
                    width: 10vw;
                    height: auto;
                }
            }

            p {
                color: $white;
                opacity: 0.5;
                font-size: 14px !important;
                font-weight: 400 !important;
                line-height: 28.33px !important;
                width: 70%;

                @media screen and (min-width: 2000px) {
                    font-size: 1vw !important;
                    line-height: 1.5vw !important;
                    padding-block: 2vw !important;
                }

                @media screen and (max-width: 767px) {
                    width: 100%;
                }
            }

            .icon_flex {
                display: flex;
                align-items: center;
                gap: 20px;

                @media screen and (max-width: 576px) {
                    justify-content: center;
                }

                svg {
                    width: 17px;
                    height: 17px;
                    color: #0FB2E3;

                    @media screen and (min-width: 2000px) {
                        width: 1.2vw;
                        height: 1.2vw;
                    }
                }
            }
        }

        .content_column {
            h6 {
                font-size: 16px !important;
                font-weight: 700 !important;
                line-height: 23.4px !important;
                letter-spacing: -0.08999999612569809px !important;
                color: $white;
                opacity: 0.8;
                padding-bottom: 18px;

                @media screen and (min-width: 2000px) {
                    font-size: 1.1vw !important;
                    line-height: 1.8vw !important;
                    padding-bottom: 1.5vw;
                }

                @media screen and (max-width: 576px) {
                    text-align: center;
                }
            }

            a {
                line-height: 26px !important;
                display: block !important;
            }

            a,
            p {
                font-size: 16px !important;
                color: $white !important;
                font-weight: 400 !important;
                padding-bottom: 10px;
                text-align: left !important;

                @media screen and (min-width: 2000px) {
                    font-size: 1.1vw !important;
                    line-height: 1.5vw !important;
                    padding-bottom: 1.5vw !important;
                }

                @media screen and (max-width: 576px) {
                    text-align: center !important;
                    font-size: 14px !important;
                }
            }
        }
    }

    .lower_upper_content {
        border: 1.67px solid #0FB2E326;
        padding: 20px 0px;

        @media screen and (min-width: 2000px) {
            padding: 1vw 0px !important;
        }

        p {
            color: #FFFFFF80 !important;
            font-size: 15px !important;
            font-weight: 400 !important;
            text-align: center;

            @media screen and (min-width: 2000px) {
                font-size: 1vw !important;
            }
        }
    }
}

.earn_section {
    background-image:
        url("../public/images/earn-img.png"),
        linear-gradient(180deg, #0A65C2 0%, $darkblue2 100%);
    background-size: cover;
    background-position: center;
    // border: 1px solid #1F7DDC;

    &.start-earning {
        background-image:
            url("../public//images/start-earning.png"),
            linear-gradient(180deg, #0A65C2 0%, $darkblue2 100%);

        @media screen and (max-width: 991px) {
            background: linear-gradient(82.02deg, $darkblue2 12.85%, #1F7DDC 87.7%);
        }
    }

    @media screen and (max-width: 991px) {
        background: linear-gradient(82.02deg, $darkblue2 12.85%, #1F7DDC 87.7%);
    }

    .center_box {
        display: flex;
        min-height: 400px;
        align-items: center;

        @media screen and (min-width: 2000px) {
            min-height: 28vw !important;
        }

        @media screen and (min-width: 1400px) {
            min-height: 500px;
        }
    }

    .contant_column {
        @media screen and (max-width: 991px) {
            width: 90%;
            margin: auto;
        }

        h2 {
            color: $white !important;
        }

        p {
            color: $white !important;
            opacity: 0.8;
            width: 70%;

            @media screen and (min-width: 2000px) {
                padding: 2vw 0px !important;
            }

            @media screen and (max-width: 991px) {
                width: 90%;
            }
        }

        .btn_earn {
            button {
                border: 1px solid #1F7DDC;
                background-color: $white;
                color: $darkblue2;
                font-family: Inter;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                text-align: center;
                padding: 15px 20px;
                border-radius: 6px;
                display: flex;
                gap: 10px;
                align-items: center;

                @media screen and (min-width: 2000px) {
                    font-size: 1vw !important;
                    line-height: normal;
                    padding: 1vw 1.5vw;
                    border-radius: 0.3vw;
                }

                @media screen and (max-width: 576px) {
                    font-size: 14px;
                    align-items: end;
                }
            }
        }
    }
}

.faq_section {
    background: linear-gradient(138.22deg, #EBF5FF 0.71%, $white 76.41%);

    &.transparent {
        background: transparent;
    }

    .global-padding {
        padding: 100px 0px !important;

        @media screen and (max-width: 576px) {
            padding: 50px 0px !important;
        }

        @media screen and (min-width: 2000px) {
            padding: 7vw 0px !important;
        }

        h2 {
            text-align: center;
            color: $text-primary01 !important;
            font-size: 42px !important;

            @media screen and (min-width: 2000px) {
                font-size: 3vw !important;
                line-height: 4.5vw !important;
            }

            @media screen and (max-width: 991px) {
                font-size: 30px !important;
                line-height: 40px !important;
            }

            @media screen and (max-width: 568px) {
                font-size: 28px !important;
                line-height: 38px !important;
            }
        }

        .gradiant-bg-btn {
            button {
                margin: auto;
                width: 197px;
                height: 50px;
            }
        }

        .accordian_box {
            .accordion {
                padding: 80px 0px;
                width: 85%;
                margin: auto;

                @media screen and (max-width: 576px) {
                    padding: 30px 0px;
                }

                @media screen and (min-width: 2000px) {
                    padding: 6vw 0px !important;
                }

                @media screen and (max-width: 991px) {
                    width: 100%;
                }

                .accordion-item {
                    border: none !important;
                    border-radius: 8px;
                    margin-top: 30px;
                    box-shadow: 0px 6px 16px 0px #4A3AFF30;
                    background-color: $white !important;
                    border: 2px solid transparent !important;

                    @media screen and (min-width: 2000px) {
                        border-radius: 1.3vw !important;
                        margin-top: 2.7vw !important;
                        border-width: 0.2vw !important;
                    }

                    &.active {
                        border-color: $darkblue2 !important;
                    }

                    &:first-of-type {
                        margin-top: 0px !important;
                    }

                    .accordion-header {
                        .accordion-button {
                            min-height: 100px !important;
                            border-radius: 8px !important;
                            font-weight: 700 !important;
                            color: $text-primary01 !important;
                            padding: 16px 30px !important;
                            box-shadow: none !important;

                            @media screen and (max-width: 576px) {
                                padding: 16px !important;
                            }

                            @media screen and (min-width: 2000px) {
                                padding: 1.5vw 2vw !important;
                                min-height: 3vw !important;
                                font-size: 1.1vw !important;
                                border-radius: 1.3vw !important;
                            }

                            &::after {
                                box-shadow: 0px 5px 16px 0px #080F340F !important;
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                background-position: center !important;
                                background-image: url("../public/images/angle-right.png") !important;
                                background-size: 10px !important;

                                @media screen and (min-width: 2000px) {
                                    width: 2.7vw;
                                    height: 2.7vw;
                                    background-size: 0.7vw !important;
                                }
                            }

                            &:not(.collapsed) {
                                background-color: transparent !important;
                                border: none !important;
                                box-shadow: none !important;

                                &::after {
                                    background-image: url("../public/images/angel.svg") !important;
                                    background: $darkblue2;
                                    background-size: 20px !important;
                                    background-repeat: no-repeat;
                                    --bs-accordion-btn-icon-transform: rotate(0deg) !important;

                                    @media screen and (min-width: 2000px) {
                                        background-size: 1.5vw !important;
                                    }
                                }
                            }
                        }
                    }

                    .accordion-collapse {
                        .accordion-body {
                            padding: 0px 30px 40px !important;
                            color: $text-primary01;
                            font-weight: 400 !important;
                            font-family: $inter;
                            width: 95%;
                            line-height: 30px;

                            @media screen and (max-width: 576px) {
                                padding: 0px 16px 20px !important;
                                width: 100%;
                            }

                            @media screen and (min-width: 2000px) {
                                padding: 0px 2vw 3vw !important;
                                font-size: 1.1vw !important;
                                line-height: 2.5vw;
                            }
                        }
                    }
                }

                // border: 2px solid $darkblue2
            }
        }
    }
}

.Login_page {
    height: 100vh;

    &.forgot_responsive {
        .login_card_column {
            .login_box {
                @media screen and (min-width: 2000px) {
                    max-width: 50% !important;
                }
            }
        }
    }

    .login_card_column {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1200px) {
            max-width: 80% !important;
            margin: auto;
        }

        @media screen and (max-width: 768px) {
            max-width: 90% !important;
            margin: auto;
        }

        .login_box {
            max-width: 500px;
            width: 100%;

            @media screen and (min-width: 2000px) {
                max-width: 70%;
            }

            .all_done_btn {
                a {
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
                    font-family: "Inter", sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    text-align: center;
                    padding: 8px 10px;
                    background: transparent;
                    padding: 10px;
                    color: $text-primary01;
                    border-radius: 5px;
                    border: 1px solid #00000026 !important;
                    width: 100%;
                    height: 50px;
                }
            }

            .logo-img {
                width: 189px;
                height: 37px;

                @media screen and (min-width: 2000px) {
                    width: 12vw !important;
                    height: auto !important;
                }
            }

            .lock-img {
                width: 64px;
                height: 64px;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;

                @media screen and (min-width: 2000px) {
                    width: 4.5vw;
                    height: auto !important;
                }
            }

            .heading_box {
                padding: 30px 0px;

                @media screen and (min-width: 2000px) {
                    padding: 5vw 0px 2vw 0px !important
                }

                h2 {
                    span {
                        -webkit-text-fill-color: transparent;
                        background: linear-gradient(90deg, #982917 47%, #e4b46c 25%, #6bb3eb 81%);
                        -webkit-background-clip: text;
                        background-clip: text;
                        -webkit-filter: saturate(1.5);
                        filter: saturate(1.5);
                    }
                }

                p {
                    padding-top: 8px;

                    @media screen and (min-width: 2000px) {
                        padding-top: 0vw !important;
                    }

                    a {
                        font-weight: 700 !important;
                        color: #5A7184 !important;
                        font-size: 16px !important;

                        @media screen and (min-width: 2000px) {
                            font-size: 1.3vw !important;
                        }
                    }
                }
            }

            .login_input_field {
                &.feedback_fields {
                    .text-area_input {
                        margin: 20px 0px;

                        @media screen and (min-width: 2000px) {
                            margin: 0.8vw 0px !important;
                        }

                        .textarea_flex {
                            display: flex;
                            flex-direction: column;
                        }

                        label {
                            margin-bottom: 10px;

                            @media screen and (min-width: 2000px) {
                                margin-bottom: 0.5w !important;
                            }
                        }

                        textarea {
                            border: 1px solid #00000026 !important;
                            border-radius: 6px !important;
                            outline: none !important;

                            @media screen and (min-width: 2000px) {
                                font-size: 1.1vw !important;
                                border-radius: 0.5vw !important;
                                border-width: 0.1vw !important;
                            }
                        }
                    }

                    .strong_div {
                        margin-bottom: 10px;

                        @media screen and (min-width: 2000px) {
                            margin-bottom: 0.2vw !important;
                        }
                    }

                    strong {
                        font-family: $inter;
                        font-size: 16px !important;
                        font-weight: 600 !important;
                        line-height: 26px !important;
                        color: #000000 !important;

                        @media screen and (min-width: 2000px) {
                            font-size: 1.1vw !important;
                            line-height: 2vw !important;
                        }
                    }

                    // textarea{
                    //     width: 100%;
                    //     border: ;
                    // }
                }

                .forget_section {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    @media screen and (min-width: 2000px) {
                        padding: 0vw !important;

                        input[type="checkbox" i] {
                            width: 1vw !important;
                            height: 1vw !important;
                            margin-right: 0.3vw !important;
                        }
                    }

                    a {
                        color: $darkblue2 !important;
                        font-size: 16px;
                        font-weight: 500;

                        @media screen and (min-width: 2000px) {
                            font-size: 1.1vw !important;
                        }
                    }

                    &.feedback {
                        flex-direction: column;
                        gap: 5px;
                        align-items: flex-start !important;

                    }
                }

                .gradiant-bg-btn {
                    button {
                        width: 100%;
                        height: 50px;

                        @media screen and (min-width: 2000px) {
                            width: 100% !important;
                            height: 3.5vw !important;
                            margin: 1vw 0px !important;
                        }
                    }
                }

                .login_footer {
                    p {
                        @media screen and (min-width: 2000px) {
                            padding-top: 0px !important;
                        }
                    }

                    a {
                        color: $darkblue2 !important;
                        font-size: 16px !important;
                        font-weight: 500 !important;
                        padding-left: 5px;

                        @media screen and (min-width: 2000px) {
                            font-size: 1.1vw !important;
                            padding-left: 0.3vw !important;
                        }
                    }

                    .button_flex_box {
                        display: flex;
                        align-items: center;
                        gap: 19px;

                        .white_btn {
                            width: 100%;

                            a,
                            button {
                                border: 1px solid #00000026;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 10px;
                                background-color: $white;
                                color: $text-primary01 !important;
                                font-family: $inter;
                                font-size: 16px;
                                font-weight: 500;
                                height: 50px;
                                border-radius: 8px;
                                width: 100%;

                                img {
                                    width: 19px;

                                    &.google_img {
                                        width: 17px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .carousal-slider-section {
        height: 100%;
        justify-content: center;
        align-items: center;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .carousel_column {
        padding: 0px 50px;
        margin: auto;
        width: 100%;

        .feedback_img {
            width: 70%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;

            @media screen and (min-width: 2000px) {

                width: 50% !important;

            }
        }

        @media screen and (min-width: 2000px) {
            width: 90%;

            img {
                width: 90%;
            }
        }

        @media screen and (max-width: 576px) {
            padding: 30px 20px;
        }

        .carousel_box_slider {
            .carousel_images {
                display: flex;
                align-items: center;
                justify-content: center;

                @media screen and (min-width: 2000px) {
                    img {
                        width: 70% !important;
                    }
                }
            }

            .heading_box {
                padding: 70px 0px 20px;
                text-align: center;

                @media screen and (min-width: 2000px) {
                    padding: 3vw 0px 0.5vw 0px !important;
                }

                h6 {
                    color: $white;

                    @media screen and (min-width: 2000px) {
                        font-size: 1.4vw !important;
                    }
                }

                p {
                    color: #FFFFFF7A !important;
                }
            }
        }

        .slick-arrow {
            display: none !important;
        }

        .slick-dots li button:before {
            color: $white !important;
        }

        .slick-dots {
            @media screen and (max-width: 991px) {
                bottom: -10px !important;
            }
        }
    }



}

.img-right {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-left: auto;

    img,
    iframe {
        width: 100%;
    }

    @media screen and (min-width: 2000px) {
        &.hero_Section_img {
            width: 95%;
        }

        width: 85%;
    }

    @media screen and (max-width: 991px) {
        justify-content: center;
        margin-top: 70px;
    }

    @media screen and (max-width: 576px) {
        justify-content: center;
        margin-top: 50px;
    }
}

.img-left {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-right: auto;

    img,
    iframe {
        width: 100%;
    }

    @media screen and (min-width: 2000px) {
        width: 85%;
    }

    @media screen and (max-width: 991px) {
        justify-content: center;
        margin-bottom: 70px;
    }
}

.Toastify__toast-container--top-right {
    padding-bottom: 0;
    position: fixed !important;
    width: 100% !important;
    bottom: 0 !important;
    align-self: flex-end !important;
    right: 0px !important;

    .Toastify__toast {
        margin-bottom: 0 !important;
        padding-left: 15px;
        padding-right: 15px;

        @media screen and (min-width: 2000px) {
            height: 4vw;
            font-size: 1.1vw;
            padding: 0vw 2vw;

            .Toastify__toast-icon {
                svg {
                    width: 1.5vw;
                    height: 1.5vw;
                }
            }

            svg {
                width: 1.5vw;
                height: 1.5vw;
            }
        }
    }

    .Toastify__toast--success {
        background-color: #e5ffea !important;
    }

    .Toastify__toast--warning {
        background-color: #fdf4e7 !important;
    }

    .Toastify__toast--error {
        background-color: #fae0e0 !important;
    }
}

.Toastify__toast-icon {
    svg {
        svg {
            width: 5vw;
            height: 5vw;
        }
    }
}

.Toastify__progress-bar {
    min-height: 0.3vw !important;
}

.pricing_route_page {
    .pricing_page {
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

.support_route_page {
    .home_page_mian_div {
        .bg-img-div {
            min-height: 100vh !important;

            .optimize_crm {
                min-height: calc(100vh - 5vw);
                display: flex;
                align-items: center;
                justify-content: center;

                .optimize_crm_content {
                    width: 100% !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    .nodata_img {
                        width: 20vw !important;

                        @media screen and (max-width: 992px) {
                            width: 30vw !important;
                        }

                        @media screen and (max-width: 768px) {
                            width: 50vw !important;
                        }

                        @media screen and (max-width: 576px) {
                            width: 70vw !important;
                        }
                    }

                    h1 {
                        font-size: 35px !important;
                        font-weight: 900 !important;

                        @media screen and (min-width: 2000px) {
                            font-size: 2.5vw !important;
                        }
                    }

                    p {
                        @media screen and (min-width: 2000px) {
                            padding-top: 0px !important;
                        }
                    }
                }
            }
        }
    }
}

.welcome_page {
    &.refund-policy {
        .optimize_crm {
            .optimize_crm_content {
                h6 {
                    @media screen and (min-width: 2000px) {
                        padding: 1.5vw 0px 1.5vw 0px !important;

                        &:last-of-type {
                            padding: 0px !important;
                        }
                    }
                }
            }
        }
    }

    .optimize_crm {
        .optimize_crm_content {
            width: 100% !important;

            h1 {
                background: linear-gradient(104.53deg, #282F97 -9.85%, #4E5DC1 91.28%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 700 !important;
            }

            h6 {
                padding: 24px 0px 50px 0px;

                @media screen and (min-width: 2000px) {
                    padding: 1.2vw 0px 3.2vw 0px !important;
                }
            }

            .optimize_image_box {
                display: flex;
                align-items: center;
                justify-content: center;

                img,
                iframe {
                    width: 60%;
                    height: 30vw;

                    @media screen and (max-width: 2000px) {
                        height: 30vw;
                    }

                    @media screen and (max-width: 576px) {
                        width: 100%;
                        height: 200px;
                    }
                }
            }
        }
    }

    .text-section {
        h6 {
            padding-top: 15px !important;

            @media screen and (min-width: 2000px) {
                padding-top: 1vw !important;
            }
        }
    }

    .grid_sections {
        background: linear-gradient(360deg, rgba(239, 247, 255, 0.8) 14.25%, rgba(255, 255, 255, 0) 100%);

        &.orange {
            background: #D99B181C !important;

            .global-padding {
                padding: 72px 0px !important;

                @media screen and (min-width: 2000px) {
                    padding: 5vw 0px !important;
                }

                h2 {
                    line-height: 58px !important;
                    color: $darkblue2 !important;

                    @media screen and (min-width: 2000px) {
                        line-height: 4vw !important;
                    }

                    @media screen and (max-width: 576px) {
                        line-height: 38px !important;
                    }
                }
            }
        }

        .grid_content {
            h1 {
                font-size: 128px !important;
                font-weight: 900 !important;
                line-height: 183.98px !important;
                text-align: left;
                color: #0FB2E30D !important;

                @media screen and (min-width: 1500px) {
                    font-size: 8vw !important;
                    line-height: 11vw !important;
                }

                @media screen and (max-width: 991px) {
                    font-size: 100px !important;
                    line-height: 150px !important;
                }

                @media screen and (max-width: 568px) {
                    font-size: 70px !important;
                    line-height: 100px !important;
                }

                &.orange {
                    color: #D2513E0D !important;
                }
            }

            p {
                @media screen and (min-width: 2000px) {
                    padding-bottom: 0px !important;
                }
            }
        }
    }

    .faq_section {
        background: transparent !important;
    }

    .img-right {
        width: 100%;

        @media screen and (max-width: 1200px) {
            width: 100%;
        }

        @media screen and (max-width: 991px) {
            justify-content: center;
            margin-top: 20px !important;
        }

        iframe,
        video {
            height: 30vw !important;
            width: 100%;

            @media screen and (max-width: 576px) {
                height: 200px !important;
            }
        }
    }

    .img-left {
        width: 95%;

        @media screen and (min-width: 2000px) {
            width: 80%;
        }

        @media screen and (max-width: 991px) {
            width: 100%;
            margin-bottom: 0px !important;
        }

        iframe,
        video {
            height: 30vw !important;
            width: 100%;

            @media screen and (max-width: 576px) {
                height: 200px !important;
            }
        }
    }
}

.optimize_video_box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: rgb(232, 233, 238);
    padding: 10px;
    border-radius: 14px;

    @media screen and (min-width: 2000px) {
        border: 0.2vw solid rgb(232, 233, 238);
        border-radius: 0.5vw;
        padding: 0.6vw;
    }

    @media screen and (max-width: 992px) {
        margin-top: 70px;
        height: 400px;
    }

    @media screen and (max-width: 576px) {
        padding: 6px;
        margin-top: 50px;
        height: 200px;
    }

    img,
    iframe {
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }
}

.affilate-program {
    .global-padding {
        padding: 70px 0px;

        @media screen and (max-width: 991px) {
            padding: 50px 0px;
        }

        @media screen and (max-width: 568px) {
            padding: 30px 0px;
        }

        @media screen and (min-width: 2000px) {
            padding: 7vw 0px;
        }
    }

    h2 {
        color: $darkblue2 !important;
        margin-bottom: 6px !important;
    }

    strong {
        font-family: $inter;
        font-size: 14px;
        font-weight: 700;
        line-height: 30px;

        @media screen and (min-width: 2000px) {
            font-size: 1vw !important;
            line-height: 2vw !important;
        }
    }

    .gradiant-bg-btn {

        button,
        a {
            min-width: 197px;
            height: 50px;
        }
    }

    .bg-img-div {
        .optimize_crm {
            padding-bottom: 52px;

            .affilate-hero-section {
                .optimize_crm_content {
                    width: 100%;
                    text-align: center;

                    h1 {
                        background: linear-gradient(104.53deg, #282F97 -9.85%, #4E5DC1 91.28%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-weight: 700 !important;
                    }

                    p {
                        padding: 24px 0 26px;
                    }

                    .button_content_box {
                        .gradiant-bg-btn {
                            button {
                                margin: auto;
                                // width: 347px;
                            }
                        }
                    }
                }

                .optimize_video_box {
                    margin-top: 62px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-inline: auto;
                    width: 50%;

                    @media screen and (max-width: 992px) {
                        width: 80%;
                    }

                    @media screen and (max-width: 992px) {
                        width: 100%;
                    }

                    iframe {
                        height: 30vw;

                        @media screen and (max-width: 2000px) {
                            height: 30vw;
                        }

                        @media screen and (max-width: 992px) {
                            height: 100%;
                        }

                        @media screen and (max-width: 576px) {
                            width: 100%;
                            height: 200px;
                        }
                    }
                }
            }
        }
    }

    .grid_sections {
        .grid_content {
            h2 {
                color: $darkblue2 !important;
                line-height: 58px;

                span {
                    font-weight: 500;
                }
            }

            .gradiant-bg-btn {
                padding-top: 33px;
            }
        }
    }

    .bg-img-skool-section {
        .cards_section {
            .heading_box {
                p {
                    padding-top: 20px;
                }
            }

            .cards_grid_section {
                padding-top: 70px;

                .card {
                    border: 2px solid $darkblue2;
                    box-shadow: 0px 6px 16px 0px #4A3AFF30;
                    border-radius: 14px;
                    padding: 0px;

                    @media screen and (min-width: 2000px) {
                        border-radius: 1vw;
                    }

                    .card-body {
                        padding: 40px 16px 20px 38px;

                        @media screen and (min-width: 2000px) {
                            padding: 4vw 1.5vw 1.5vw 3vw !important;
                        }

                        h5 {
                            color: #383838;
                        }

                        .tabs-portion {
                            .tabs-container {
                                padding-top: 21px;

                                .tabs-header {
                                    background-color: #E0E3ED;
                                    border-radius: 10px;
                                    padding: 8px 10px;
                                    margin-bottom: 23px;
                                    display: flex;
                                    gap: 5px;
                                    align-items: center;
                                    flex-wrap: nowrap;
                                    overflow-y: auto;

                                    &::-webkit-scrollbar {
                                        height: 6px;

                                        @media screen and (min-width: 2000px) {
                                            height: 0.5vw;
                                        }
                                    }

                                    &::-webkit-scrollbar-track {
                                        background: #f1f1f1;
                                        border-radius: 10px;
                                    }

                                    &::-webkit-scrollbar-thumb {
                                        background: #C4C4C4;
                                        border-radius: 10px;
                                    }

                                    &::-webkit-scrollbar-thumb:hover {
                                        background: #C4C4C4;
                                    }

                                    @media screen and (min-width: 2000px) {
                                        padding: 0.5vw 0.7vw;
                                        border-radius: 0.4vw;
                                        margin-bottom: 2.3vw;
                                    }

                                    .tab-button {
                                        background-color: transparent;
                                        border: none;
                                        padding: 6px 12px;
                                        font-size: 14px;
                                        font-weight: 400;
                                        font-family: $inter;
                                        color: #5A7184;
                                        border-radius: 5px;
                                        text-wrap: nowrap;

                                        @media screen and (min-width: 2000px) {
                                            padding: 0.5vw 1.2vw;
                                            font-size: 0.9vw;
                                            border-radius: 0.4vw;
                                        }

                                        &.active {
                                            background-color: $darkblue2;
                                            color: $white;
                                            font-weight: 600;
                                        }
                                    }
                                }

                                .tabs-content {
                                    .general-tab {
                                        .scroller-general-div {
                                            // max-height: 60vh;
                                            // overflow-y: auto;
                                            //     &::-webkit-scrollbar {
                                            //         width: 6px; 
                                            //     }

                                            //     &::-webkit-scrollbar-track {
                                            //         background: #f1f1f1;
                                            //         border-radius: 10px; 
                                            //     }

                                            //     &::-webkit-scrollbar-thumb {
                                            //         background: $darkblue; 
                                            //         border-radius: 10px; 
                                            //     }

                                            //     &::-webkit-scrollbar-thumb:hover {
                                            //         background: $darkblue; 
                                            //     }
                                        }

                                        .spacing-general-div {
                                            padding-bottom: 15px;

                                            @media screen and (min-width: 2000px) {
                                                padding-bottom: 1.2vw;
                                            }
                                        }

                                        h3 {
                                            color: #383838 !important;
                                            font-family: $inter;
                                            font-size: 20px !important;
                                            font-weight: 800 !important;
                                            line-height: 30px !important;

                                            @media screen and (min-width: 2000px) {
                                                font-size: 1.5vw !important;
                                                line-height: 2.5vw !important;
                                            }
                                        }

                                        .general-tab-block {
                                            &:not(&:last-child) {
                                                padding-bottom: 50px;

                                                @media screen and (min-width: 2000px) {
                                                    padding-bottom: 3vw;
                                                }
                                            }

                                            ul {
                                                // display: list-item;

                                                li {
                                                    list-style: inside;
                                                    display: list-item;
                                                    color: #383838 !important;
                                                    font-family: $inter !important;
                                                    font-size: 14px !important;
                                                    font-weight: 400 !important;
                                                    line-height: 30px !important;

                                                    @media screen and (min-width: 2000px) {
                                                        font-size: 1vw !important;
                                                        line-height: 2vw !important;
                                                    }
                                                }
                                            }

                                            p {
                                                color: #383838 !important;
                                                font-family: $inter;
                                                font-size: 14px;
                                                font-weight: 400;
                                                line-height: 30px;

                                                @media screen and (min-width: 2000px) {
                                                    font-size: 1vw !important;
                                                    line-height: 2vw !important;
                                                }
                                            }
                                        }

                                        .view-more-btn {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;

                                            &.second {
                                                margin-top: 20px;

                                                @media screen and (min-width: 2000px) {
                                                    margin-top: 2vw;
                                                }
                                            }

                                            button {
                                                background-color: transparent;
                                                border: none;
                                                color: $darkblue2;
                                                font-family: $inter;
                                                font-size: 14px;
                                                font-weight: 600;
                                                line-height: 30px;

                                                @media screen and (min-width: 2000px) {
                                                    font-size: 1.1vw;
                                                    line-height: 2vw;
                                                }
                                            }
                                        }

                                        .copy-btn {
                                            padding-top: 15px;

                                            @media screen and (min-width: 2000px) {
                                                padding-top: 1vw;
                                            }

                                            button {
                                                background-color: $darkblue2;
                                                border: 1px solid $darkblue2;
                                                color: $white;
                                                font-family: $inter;
                                                font-size: 14px;
                                                font-weight: 600;
                                                line-height: 28px;
                                                border-radius: 5px;
                                                padding: 6px 18px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                gap: 4px;

                                                svg {
                                                    width: 20px;
                                                    height: 20px;
                                                    margin-right: 4px;
                                                }

                                                @media screen and (min-width: 2000px) {
                                                    font-size: 1vw !important;
                                                    line-height: normal !important;
                                                    padding: 0.7vw 1.5vw !important;
                                                    border-radius: 0.4vw;
                                                    gap: 0.3vw;
                                                    border-width: 0.1vw;

                                                    svg {
                                                        width: 1.3vw !important;
                                                        height: 1.3vw !important;
                                                    }
                                                }

                                                &:hover {
                                                    background-color: $white;
                                                    color: $darkblue2;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .cards_education_grid_section {
                padding-top: 54px;
                width: 85%;
                margin: auto;

                @media screen and (max-width: 991px) {
                    width: 100%;
                }

                @media screen and (min-width: 2000px) {
                    padding-top: 5vw;
                }

                .card {
                    box-shadow: 0px 0px 20px 0px #00000012;
                    border: none;
                    padding: 20px 16px 50px;

                    @media screen and (min-width: 2000px) {
                        padding: 1.5vw 1.1vw 4vw;
                    }

                    .card-body {
                        padding: 0;

                        .card-header {
                            background-color: transparent;
                            border: none;
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            padding: 0;

                            @media screen and (min-width: 2000px) {
                                gap: 0.5vw;
                            }

                            .img-box {
                                width: 60px;
                                height: 60px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                object-fit: cover;

                                @media screen and (min-width: 2000px) {
                                    width: 4vw;
                                    height: 4vw;
                                }

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            h6 {
                                font-size: 18px;
                                color: #130F26;

                                @media screen and (min-width: 2000px) {
                                    font-size: 1.5vw !important;
                                    line-height: 2vw !important;
                                }
                            }

                            p {
                                font-size: 12px;

                                @media screen and (min-width: 2000px) {
                                    font-size: 1vw !important;
                                    line-height: 2vw !important;
                                }
                            }
                        }

                        .card-rating-portion {
                            padding-top: 20px;

                            @media screen and (min-width: 2000px) {
                                padding-top: 1.5vw;
                            }

                            .star-portion {
                                svg {
                                    width: 15px;
                                    height: 15px;
                                    color: #FFA033 !important;

                                    @media screen and (min-width: 2000px) {
                                        width: 1.2vw;
                                        height: 1.2vw;
                                    }
                                }

                                h6 {
                                    font-size: 16px !important;
                                    padding: 5px 0px 11px;
                                    font-weight: 600;

                                    @media screen and (min-width: 2000px) {
                                        font-size: 1.1vw !important;
                                        line-height: 2vw !important;
                                        padding: 0.6vw 0 1vw;
                                    }
                                }

                                p {
                                    font-size: 12px;
                                    line-height: 15px !important;

                                    @media screen and (min-width: 2000px) {
                                        font-size: 0.8vw !important;
                                        line-height: 1.3vw !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .graphics_sections {
        .card {
            box-shadow: 0px 5px 16px 0px #080F340F;
            border: none;
            padding: 40px 20px;
            border-radius: 18px;

            @media screen and (min-width: 2000px) {
                padding: 2.5vw 2vw;
                border-radius: 1vw;
            }

            .card-body {
                padding: 0px;

                h5 {
                    color: #383838;

                    @media screen and (min-width: 2000px) {
                        padding-top: 1.6vw;
                        line-height: 2.1vw;
                    }
                }

                .white-bg-btn {
                    padding-top: 20px;

                    @media screen and (min-width: 2000px) {
                        padding-top: 1.5vw;
                    }

                    button,
                    a {
                        font-family: $inter;
                        font-size: 10.6px;
                        font-weight: 500 !important;
                        line-height: 12.83px !important;
                        color: $darkblue2 !important;
                        min-height: 40px;
                        width: fit-content;

                        @media screen and (min-width: 2000px) {
                            min-height: 3vw;

                        }
                    }
                }
            }
        }
    }

    .affilate-program {
        background: linear-gradient(138.22deg, #EBF5FF 0.71%, $white 76.41%);
        position: relative;
        padding-bottom: 100px;

        .affilate-program-card {
            position: absolute;
            top: 5%;
            z-index: 999;

            @media screen and (max-width: 991px) {
                position: static;
                margin-bottom: 30px;
            }

            .card {
                border: none;
                background-color: transparent;

                h2 {
                    br {
                        display: block !important;

                        @media screen and (max-width: 1500px) {
                            display: block !important;
                        }

                        @media screen and (max-width: 991px) {
                            display: none !important;
                        }
                    }
                }

                p {
                    br {
                        display: block !important;

                        @media screen and (max-width: 1500px) {
                            display: block !important;
                        }

                        @media screen and (max-width: 991px) {
                            display: none !important;
                        }
                    }
                }

                .button_content_box {
                    margin-top: 30px;
                }
            }
        }

        .counting-cards {
            position: relative;

            .line-bg-img {
                width: 100%;
                height: auto;
                object-fit: contain;

                @media screen and (max-width: 991px) {
                    display: none;
                }
            }

            .card {
                background-color: transparent;
                border: none;

                // &::after {
                //     font-family: $inter;
                //     font-size: 204px;
                //     font-weight: 700;
                //     line-height: 204px;
                //     letter-spacing: -0.02em;
                //     color: #eaedf2;
                //     position: absolute;
                //     right: -1vw;
                //     top: 1vw;

                //     @media screen and (min-width: 2000px) {
                //         font-size: 13vw;
                //         top: 5vw;
                //         right: -3vw;
                //     }
                //     @media screen and (min-width: 1600px) and (max-width:2000px) {
                //         top: 2vw;
                //         right: -1vw;
                //     }
                //     @media screen and (max-width: 991px) {
                //        content: "" !important;
                //     }
                // }

                // .position-box {
                //     z-index: 1;
                // }

                p {

                    br {
                        display: block !important;

                        @media screen and (max-width: 1500px) {
                            display: block !important;
                        }

                        @media screen and (max-width: 991px) {
                            display: none !important;
                        }
                    }
                }

                &.one {
                    position: absolute;
                    bottom: -3%;
                    left: 6%;

                    @media screen and (min-width: 1600px) and (max-width:2000px) {
                        bottom: -3vw !important;
                        // bottom: -4vw !important;
                        // left: 10vw;
                    }

                    @media screen and (min-width: 1200px) {
                        bottom: -5vw;
                        left: 7vw;
                        // left: 11vw;
                    }

                    @media screen and (max-width: 1199px) {
                        bottom: -8vw;
                        left: 4vw;
                    }

                    @media screen and (max-width: 991px) {
                        position: static;
                    }

                    // &::after {
                    //     content: "1";
                    //     @media screen and (min-width: 1600px) and (max-width:2000px) {
                    //         top: 2vw;
                    //     }
                    // }
                }

                &.second {
                    @media screen and (min-width: 1600px) and (max-width:2000px) {
                        bottom: 5vw !important;
                    }

                    @media screen and (min-width: 1200px) {
                        position: absolute;
                        bottom: 4vw;
                        left: 56%;
                        // left: 60%;
                        transform: translateX(-50%);
                    }

                    @media screen and (max-width: 1199px) {
                        position: absolute;
                        bottom: 2vw;
                        left: 56%;
                        transform: translateX(-50%);
                    }

                    @media screen and (max-width: 991px) {
                        position: static;
                        transform: translateX(0%);
                    }

                    // &::after {
                    //     content: "2";
                    // }
                }

                &.three {
                    position: absolute;
                    top: 4%;
                    right: -4%;

                    @media screen and (min-width: 1600px) and (max-width:2000px) {
                        top: -1vw !important;
                    }

                    @media screen and (min-width: 1200px) {
                        top: 0;
                        right: -3vw;
                    }

                    @media screen and (max-width: 1199px) {
                        right: -3vw;
                        top: 2vw;
                    }

                    @media screen and (max-width: 991px) {
                        position: static;
                    }

                    // &::after {
                    //     content: "3";
                    // }
                }

                .boxes {
                    width: 64px;
                    height: 64px;
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $white;
                    margin-bottom: 20px;

                    @media screen and (min-width: 1200px) {
                        width: 4.5vw;
                        height: 4.5vw;
                        border-radius: 1.2vw;
                    }

                    p {
                        width: 23px;
                        height: 23px;
                        border-radius: 50%;
                        background-color: #C4C4C4;

                        @media screen and (min-width: 1200px) {
                            width: 1.5vw;
                            height: 1.5vw;
                        }
                    }
                }

                .sign-up-paragraph {
                    color: $darkblue2;
                    font-weight: 700;
                }
            }
        }
    }
}